import clsx from "clsx";
import React from "react";

export enum BodyCopySizeOption {
	Default,
	Small,
	Large,
}

export interface BodyCopyProps {
	text: string;
	size?: BodyCopySizeOption;
	className?: string;
}

export const BodyCopy = ({ text, size, className }: BodyCopyProps) => {
	if (!text) return null;

	let sizeClasses = "p-small";
	if (size === BodyCopySizeOption.Small) {
		sizeClasses = "p-xsmall";
	}
	if (size === BodyCopySizeOption.Large) {
		sizeClasses = "p-large";
	}

	return <div className={clsx(sizeClasses, className)} dangerouslySetInnerHTML={{ __html: text }} />;
};
