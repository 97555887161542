import React, { useState } from "react";
import { motion } from "framer-motion";
import MenuLinkItem from "./MenuLinkItem";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Link } from "gatsby";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { IconType } from "~/components/elements/Icon";
import Image from "~/components/elements/Image";
import { Images } from "../../flexible/TabbedSlider";
import clsx from "clsx";

const MegaMenu = ({ cta, items, onLinkClick, setActiveMenu }) => {
	const [activeImage, setActiveImage] = useState(0);

	return (
		<motion.div
			onMouseLeave={() => setActiveMenu(null)}
			className="absolute left-0 right-0 top-[calc(100%-10px)] z-0"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3 }}
		>
			<div className="rounded-b-lg bg-white pt-2.5">
				<div className="flex w-full">
					{/* Left CTA Block */}
					<div className="flex w-full max-w-[40%] flex-auto rounded-bl-lg p-3 xl:max-w-[490px]">
						<div className="w-full rounded-lg bg-green-4 transition-colors duration-300 ease-in-out hover:bg-green-7">
							<MegaMenuCTA onLinkClick={onLinkClick} {...cta} />
						</div>
					</div>

					<div className="flex w-full flex-auto rounded-br-lg bg-white">
						<div className="items-between flex w-full justify-between pt-3 md:gap-6">
							{/* Links */}
							<div className="space-y-[34px] pb-8">
								{items?.map((item, i) => (
									<div key={`megaItem${i}`} onMouseEnter={() => setActiveImage(i)}>
										<MenuLinkItem
											linkClasses={clsx(
												i === 0 &&
													"pb-[105px]x rounded-lg bg-purple-4 px-10 pb-10 pt-10 transition-colors duration-300 ease-in-out hover:bg-purple-3 xl:px-12 xl:pb-[105px] xl:pt-12"
											)}
											classes={clsx(i !== 0 && "px-8")}
											largeTitle
											{...item}
											isLarge={i === 0}
											showBottomBorder={i !== 0}
										/>
									</div>
								))}
							</div>

							{/* Images */}
							<div className="pointer-events-none h-full w-full self-stretch pb-3 pr-3 md:max-w-[250px] xl:max-w-[389px]">
								<Images slides={items} active={activeImage} rounded fade />
							</div>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
};

const MegaMenuArrow = () => {
	return (
		<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.1133 2.60283H11.1312C11.6254 2.62449 12.0123 3.03589 12.0048 3.53013V11.6065C12.0038 12.0989 11.6056 12.498 11.1133 12.4999H3.03231C2.53807 12.5074 2.12669 12.1205 2.10502 11.6263C2.10031 11.3862 2.19257 11.1556 2.36014 10.9842C2.52771 10.8129 2.75741 10.7169 2.99652 10.7169H7.76012C7.96158 10.7169 8.14423 10.5954 8.22142 10.409C8.29861 10.2226 8.25531 10.008 8.11222 9.86584L0.260935 2.01263C0.0914831 1.84411 -0.00267124 1.61348 0.000172615 1.37436C0.00205517 1.13525 0.0999613 0.906486 0.272237 0.740799C0.637496 0.405665 1.20235 0.422607 1.54691 0.777513L9.37843 8.60446C9.52152 8.74755 9.73617 8.79086 9.92256 8.71272C10.109 8.63552 10.2304 8.4529 10.2294 8.25142V3.48975C10.2323 3.00211 10.6258 2.6067 11.1134 2.60295L11.1133 2.60283Z"
				fill="black"
			/>
		</svg>
	);
};

const MegaMenuCTA = ({ image, link, headingLink, links, content, heading, onLinkClick }) => {
	return (
		<div className="h-full w-full lg:p-12">
			<div className="flex h-full flex-col justify-start gap-[38px]">
				<Link
					onClick={() => {
						onLinkClick?.();
					}}
					to={headingLink?.url}
					className="group"
				>
					<div className="flex items-center justify-between">
						<Heading type="h5" size={HeadingSizeOption.h5} text={heading} />

						<span className="-translate-x-2 -translate-y-1 opacity-0 transition-[opacity,transform] duration-300 ease-in-out group-hover:translate-x-0 group-hover:translate-y-0 group-hover:opacity-100">
							<MegaMenuArrow />
						</span>
					</div>

					<div className="mt-3 opacity-60">
						<BodyCopy size={BodyCopySizeOption.Default} text={content} />
					</div>
				</Link>

				{/* Links */}
				<div className="flex items-end justify-between gap-12">
					<div className="flex flex-shrink-0 flex-col gap-2">
						{links?.map((link, i) => (
							<Button
								preLinkText={`${i + 1}`}
								key={`mega-menu-cta-link-${i}`}
								hideIcon
								type={ButtonType.Text}
								link={link?.link}
								icon={IconType.ArrowRightDown}
								onClick={onLinkClick}
							/>
						))}
					</div>

					<Image image={image} className="pointer-events-none hidden w-full max-w-[170px] flex-shrink-0 translate-y-10 xl:block" />
				</div>
			</div>
		</div>
	);
};

export default MegaMenu;
