import React from "react";
import { Button, ButtonType } from "./Button";

const ButtonGroup = ({ links, className = "" }) => {
	return (
		<div className={`inline-flex w-auto flex-col space-y-[10px] md:flex md:flex-row md:space-x-[20px] md:space-y-0 ${className}`}>
			{/* Need to sort out type names from cms */}
			{links?.length > 0 &&
				links?.map((link, i) => (
					<Button
						key={`button-group-button-${i}`}
						type={link?.type}
						link={link?.link}
						icon={link?.icon}
						backgroundColor={link?.backgroundColor}
						hoverBackgroundColor={link?.hoverBackgroundColor}
					/>
				))}
		</div>
	);
};

export default ButtonGroup;
