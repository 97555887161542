import { throttle } from "lodash";
import { useEffect, useState } from "react";

function useDocumentScrollThrottled(callback) {
	const [, setScrollPosition] = useState(0);
	let previousScrollTop = 0;

	function handleDocumentScroll() {
		const { scrollTop: currentScrollTop } = document.documentElement || document.body;

		setScrollPosition((previousPosition) => {
			previousScrollTop = previousPosition;
			return currentScrollTop;
		});

		callback({ previousScrollTop, currentScrollTop });
	}

	const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 150);

	useEffect(() => {
		window.addEventListener("scroll", handleDocumentScrollThrottled, {
			passive: true,
		});

		return () => window.removeEventListener("scroll", handleDocumentScrollThrottled);
	});
}

export default useDocumentScrollThrottled;
