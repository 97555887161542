import React from "react";
import { Link } from "gatsby";

const HeaderLinks = ({ headerLink }) => (
	<div className="flex items-center">
		{/* <Link
      to={headerLink[0]?.link?.url}
      className="mr-2.5 xl:mr-5 leading-[0.943] opacity-40 hover:opacity-100 duration-200 ease-in-out "
    >
      {headerLink[0]?.link?.title}
    </Link> */}
		<Link
			to={headerLink?.[0]?.link?.url}
			className="rounded-[3px] bg-purple-2 px-[46px] py-[12px] text-[14px] font-normal leading-[1em] tracking-[-0.02em] text-white transition duration-200 ease-in-out hover:scale-95 hover:bg-dark-purple"
		>
			{headerLink?.[0]?.link?.title}
		</Link>
	</div>
);

export default HeaderLinks;
