import { AnimatePresence } from "framer-motion";
import { Link } from "gatsby";
import React, { useState } from "react";
import useDocumentScrollThrottled from "~/hooks/useDocumentScrollThrottled";
import Image from "~/components/elements/Image";
import useBlogPosts from "~/hooks/useBlogPosts";
import HeaderBanner from "./HeaderBanner";
import HeaderLinks from "./HeaderLinks";
import HeaderNav from "./HeaderNav";
import MegaMenu from "./MegaMenu";
import MobileNav from "./MobileNav";
import clsx from "clsx";

const MINIMUM_SCROLL = 80;

export default function Header(props) {
	const { data, showPromoBar = false } = props;

	const {
		header: { headerLink, headerLogo, headerMenu },
	} = data;

	const [activeMenu, setActiveMenu] = useState(null);
	const [showMenu, setShowMenu] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	const { allWpPost } = useBlogPosts();

	useDocumentScrollThrottled((callbackData) => {
		if (typeof window === "undefined") return;

		const { previousScrollTop, currentScrollTop } = callbackData;
		const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

		setIsScrolled(isMinimumScrolled);
	});

	const enterHandler = (i) => {
		setActiveMenu(i);
		setShowMenu(true);
	};

	const exitHandler = () => {
		setActiveMenu(null);
		setShowMenu(false);
	};

	return (
		<>
			<header
				onMouseLeave={() => setActiveMenu(null)}
				className={clsx("bg-gray-50 relative left-6 right-6 z-[150] hidden lg:fixed lg:block", showPromoBar ? "top-0" : "top-4")}
			>
				{/* Banner */}
				{showPromoBar && <HeaderBanner allWpPost={allWpPost} />}

				<div
					className={clsx(
						showPromoBar ? "rounded-t-none" : "rounded-t-[10px]",
						"relative z-[10] mx-auto max-w-[1350px] rounded-b-[10px] bg-white transition-opacity duration-300 ease-in-out",
						isScrolled ? "shadow" : "shadow-none"
					)}
				>
					<div className="flex w-full items-center px-[26px] py-[18px]">
						{/* Header logo, join our team */}
						<div className="flex items-center">
							<Link to={"/"}>
								<Image image={headerLogo} />
							</Link>

							<JoinTeam text={"Join our team!"} />
						</div>

						<div className="ml-[194px] flex flex-1 items-center justify-between">
							<div className="flex items-center space-x-5 lg:mr-4 lg:space-x-4 xl:mr-[60px] xl:space-x-12">
								{headerMenu?.map((menuItem, i) => (
									<HeaderNav
										key={`navHead${i}`}
										i={i}
										activeMenu={activeMenu}
										setActiveMenu={setActiveMenu}
										enterHandler={enterHandler}
										exitHandler={exitHandler}
										{...menuItem}
									/>
								))}
							</div>

							{/* Right links */}
							<HeaderLinks headerLink={headerLink} />
						</div>
					</div>

					<AnimatePresence>
						{showMenu && headerMenu[activeMenu]?.subnav === "megamenu" && (
							<MegaMenu
								setActiveMenu={setActiveMenu}
								{...headerMenu[activeMenu]?.megaMenu}
								onLinkClick={() => {
									exitHandler();
								}}
							/>
						)}
					</AnimatePresence>
				</div>
			</header>

			<MobileNav headerLink={headerLink} headerLogo={headerLogo} headerMenu={headerMenu} />
		</>
	);
}

export const JoinTeam = ({ text }) => (
	<Link
		to="/careers"
		className="ml-[11px] hidden rounded-[4px] bg-purple-1 bg-opacity-[0.20] px-[7px] py-[2px] text-[12px] font-medium leading-4 tracking-[-0.01em] text-purple-2 transition duration-200 ease-in-out hover:scale-[0.99] hover:bg-purple-2 hover:text-white xl:block"
	>
		{text}
	</Link>
);
