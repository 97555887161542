import { Link } from "gatsby";
import React from "react";
import { Icon } from "~/components/elements/Icon";
import { WpAcfLink } from "~/_generated/types";

export enum ButtonType {
	Button = "button",
	Text = "text",
	Outline = "button-outline",
}

export interface ButtonProps {
	link: WpAcfLink;
	type: ButtonType;
	icon: string;
	backgroundColor?: string;
	hoverBackgroundColor?: string;
	className?: string;
	noGatsbyLink?: boolean;
	newTab?: boolean;
	hideIcon?: boolean;
	noLink?: boolean;
	preLinkText?: string;
	onClick?: () => void;
}

const backgroundColorMap = {
	purple2: "!bg-purple-2 !text-white",
	vibrantBlue: "!bg-vibrant-blue !text-white",
};

const hoverBackgroundColorMap = {
	black: "hover:!bg-black hover:!text-white",
	white: "hover:!bg-white hover:!text-black",
};

export const Button = (props: ButtonProps) => {
	const {
		link,
		type,
		icon,
		backgroundColor = "purple2",
		hoverBackgroundColor = "white",
		className = "",
		noGatsbyLink = false,
		newTab = false,
		onClick,
		hideIcon = false,
		noLink,
		preLinkText,
	} = props;

	if (!link) return null;

	let typeClasses = "";
	if (type === ButtonType.Button) {
		typeClasses = `button bg-purple-2x solid-button rounded-[4px] solid-btn py-[15px] px-[34px] lg:px-[48px] hover:!bg-black hover:text-white hover:scale-95 ${className} ${backgroundColorMap?.[backgroundColor]} ${hoverBackgroundColorMap?.[hoverBackgroundColor]}`;
	} else if (type === ButtonType.Text) {
		typeClasses = "text-link max-w-max";
	} else if (type === ButtonType.Outline) {
		typeClasses =
			"bg-transparent outline-button rounded-[4px] py-[15px] px-[34px] lg:px-[49px] hover:bg-white hover:scale-95 hover:text-black border border-black hover:border-white";
	} else {
		typeClasses = "hover:opacity-50 transition-opacity";
	}

	if (noLink) {
		return (
			<div className={`group inline-block w-full transition duration-200 ease-in-out md:max-w-max ${typeClasses}`}>
				<InnerButton link={link} type={type} icon={icon} hideIcon={hideIcon} />
			</div>
		);
	}

	return !noGatsbyLink ? (
		<Link
			onClick={() => {
				onClick?.();
			}}
			target={newTab ? "_blank" : "_self"}
			to={link?.url || "#"}
			className={`group inline-block w-full transition duration-200 ease-in-out md:max-w-max ${typeClasses}`}
		>
			<InnerButton link={link} type={type} icon={icon} hideIcon={hideIcon} preLinkText={preLinkText} />
		</Link>
	) : (
		<a
			onClick={() => {
				onClick?.();
			}}
			target={newTab ? "_blank" : "_self"}
			href={link?.url || "#"}
			className={`group inline-block w-full transition duration-200 ease-in-out md:max-w-max ${typeClasses}`}
		>
			<InnerButton link={link} type={type} icon={icon} hideIcon={hideIcon} preLinkText={preLinkText} />
		</a>
	);
};

const InnerButton = ({ link, type, icon, hideIcon, preLinkText = "" }) => {
	return (
		<>
			<div className={`relative flex w-full items-center justify-center overflow-hidden ${type === ButtonType.Text && "pt-1"}`}>
				{/* Non hover content */}
				<div className="relative whitespace-nowrap transition-transform duration-500 ease-in-out group-hover:-translate-y-full">
					<span>
						<span className="mr-[6px] font-heading">{preLinkText}</span>
						{link?.title}
					</span>
					{!hideIcon && (
						<div className="-mt-0.5 ml-[16px] inline-block">
							<Icon type={icon} />
						</div>
					)}
				</div>

				{/* Hover content */}
				<div
					className={`absolute translate-y-full transform whitespace-nowrap group-hover:translate-y-0 ${
						type !== ButtonType.Text && "scale-110"
					} transition-transform duration-500 ease-in-out group-hover:scale-100`}
				>
					<span>
						<span className="mr-[6px] font-heading">{preLinkText}</span>
						{link?.title}
					</span>
					{!hideIcon && (
						<div className="-mt-0.5 ml-[16px] inline-block">
							<Icon type={icon} />
						</div>
					)}
				</div>
			</div>

			{type === ButtonType.Text && <div className="link-underline mt-[5px] h-[1px] w-full" />}
		</>
	);
};
