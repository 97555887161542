import { Link } from "gatsby";
import React from "react";

import { Button, ButtonType } from "~/components/elements/buttons/Button";
import Image from "~/components/elements/Image";
import { Icon, IconType } from "~/components/elements/Icon";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import ButtonGroup from "~/components/elements/buttons/ButtonGroup";
import { useLocation } from "@reach/router";

export const legalNav = [{ url: "/privacy-policy", title: "Privacy Policy" }];

export default function Footer({ data, hideFooterCta = false }) {
	const { footerNav, footerCta } = data?.footer;
	const { hideLinkPages } = data?.footer?.footerNav || {};
	const pagesToHideLinkOn = hideLinkPages?.split(",") || [];
	const location = useLocation();
	const hideLink = pagesToHideLinkOn.includes(location.pathname.replace(/\//g, ""));

	const scrollToTop = () => {
		window && window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<>
			{/* {!hideFooterCta && <FooterCta {...footerCta} />} */}
			<footer className="relative z-[100] mx-auto -mt-5 rounded-t-[20px] bg-dark-purple lg:pb-[123px]">
				<div className="container">
					<div className="py-10 lg:pt-[94px] xl:pb-0">
						<div className="relative items-start xl:flex">
							{/* Logo, scroll to top */}
							<div className="mb-[30px] flex items-center justify-between md:mt-0 xl:mb-0 xl:mt-6">
								{/* Logo */}
								<Link to="/" className="block">
									<Image className="block w-20 invert md:w-[126px]" image={footerNav?.logo} />
								</Link>

								{/* Mobile Scroll to top */}
								<button
									role="button"
									aria-label="Scroll to top"
									onClick={scrollToTop}
									className="cursor-pointer opacity-60 transition-opacity duration-200 hover:opacity-100 xl:hidden"
								>
									<Icon type={"scrollToTopButton"} />
								</button>
							</div>

							<div className="flex-1 xl:ml-[180px]">
								{/* Heading */}
								<h3 className="text-50 footer-heading flex flex-col font-normal">
									{footerNav?.heading}
									<span className="text-purple sm:ml-0 sm:block">{footerNav?.tagline}</span>
								</h3>

								{/* CTA link */}
								{!hideLink && (
									<div className="mt-8 lg:mt-[60px]">
										<Button type={ButtonType.Button} link={footerNav?.link} icon={IconType.ArrowRightDown} />
									</div>
								)}

								{/* Menu */}
								<div className="mt-12 grid grid-cols-2 gap-8 md:grid-cols-3 md:gap-0 xl:mt-[84px]">
									{footerNav?.nav?.length > 0 && footerNav?.nav?.map((navItem, i) => <FooterNavSection key={`navGroup-${i}`} {...navItem} />)}
								</div>
							</div>

							{/* Scroll to top */}
							<button
								role="button"
								aria-label="Scroll to top"
								onClick={scrollToTop}
								className="ml-auto hidden cursor-pointer opacity-60 transition-opacity duration-200 hover:opacity-100 xl:block"
							>
								{/* <div className="translate-y-4x transformx"> */}
								<Icon type={"scrollToTopButton"} />
								{/* </div> */}
							</button>
						</div>
					</div>

					{/* Image */}
					<div className="mb-10 w-[163px] lg:mb-[76px]">
						<Image image={footerNav?.graphic} className="h-full w-full" />
					</div>

					{/* Copyright and terms */}
					<LegalNav {...footerNav?.legalNav} links={legalNav} />
				</div>
			</footer>
		</>
	);
}

const LegalNav = ({ icon, copyright, links }) => (
	<div className="lg:px-6">
		<div className="mx-auto w-full max-w-[1200px] rounded-t-[10px] bg-white bg-opacity-[0.12] lg:rounded-[10px]">
			<div className="flex flex-col-reverse items-center justify-between p-6 xs:flex-row md:px-[42px] md:py-[34px]">
				{/* Image */}
				<div className="flex items-center justify-center space-x-[21px] opacity-50 lg:mt-0 lg:justify-start">
					<div className="w-[30px]">
						<Image image={icon} className="w-full object-contain" />
					</div>

					<span className="whitespace-nowrap">© {copyright}</span>
				</div>

				<div className="p-small flex items-center justify-center space-x-[14px] lg:justify-end">
					{links?.map((link, i) => (
						<Link key={`legNav${i}`} className="opacity-50 transition-opacity hover:opacity-100" to={link?.url}>
							{link?.title}
						</Link>
					))}
				</div>
			</div>
		</div>
	</div>
);

export const FooterNavSection = ({ heading, links }) => (
	<div className="border-l border-white/10 border-opacity-30 px-4 md:px-8">
		{/* Heading */}
		<div className="mb-[22px] block text-[18px] leading-[22px]">{heading}</div>

		{/* Links */}
		<div className="space-y- flex flex-col items-start space-y-[4px]">
			{links?.map((link, i) => (
				<Link
					key={i}
					to={link?.link?.url || "#"}
					className="p-small relative inline-flex w-auto items-center opacity-60 transition-[opacity,colors] duration-300 ease-in-out hover:text-green-2 hover:opacity-100"
				>
					{link?.link?.title}

					{link?.link?.title === "Careers" && (
						<div className="relative ml-[10px] max-w-max rounded-[4px] bg-[#4E5A6D] px-[4px] py-[1px] text-[12px] tracking-[-0.01em]">Hiring!</div>
					)}
				</Link>
			))}
		</div>
	</div>
);

export const FooterCta = ({ content, heading, image, links }) => {
	return (
		<section className="mx-auto bg-green-cta text-white">
			<div className="container">
				<div className="flex flex-wrap py-[70px] md:pb-[75px] md:pt-[139px]">
					<div className="w-full md:w-7/12">
						<Border size={BorderSizeOption.Hero}>
							<Heading as="h2" size={HeadingSizeOption.h1} text={heading} />

							<div className="mt-[35px] max-w-[503px]">
								<BodyCopy size={BodyCopySizeOption.Large} text={content} />
							</div>

							<div className="mt-12 md:mt-20 lg:mt-[166px]">
								<ButtonGroup links={links} className="!w-full md:!w-auto" />
							</div>
						</Border>
					</div>

					<div className="w-full md:w-5/12">
						<div className="md:ml-[76px]">
							<div className="mt-12 md:mt-20">
								<Image image={image} className="ml-auto h-full w-full md:max-w-[419px]" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
