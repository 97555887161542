import { graphql, useStaticQuery } from "gatsby";

const useBlogPosts = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpPost(sort: { fields: date, order: DESC }) {
				nodes {
					date
					dateGmt
					title
					nodeType
					slug
					uri
					excerpt
					article {
						readwatchlistenTime
						image {
							...Image
						}
					}
					featuredImage {
						node {
							...Image
						}
					}
				}
			}
		}
	`);

	return data;
};

export default useBlogPosts;
