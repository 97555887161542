import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";

import { WpPage_Page_FlexibleContent_TabbedSlider } from "~/_generated/types";
import { Icon, IconType } from "~/components/elements/Icon";
import { AnimatePresence, motion } from "framer-motion";
import Border, { BorderSizeOption } from "~/components/elements/Border";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import Image from "~/components/elements/Image";
import { Section } from "~/components/elements/Section";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";

export default function TabbedSlider({ layout, heading, slides, section }: WpPage_Page_FlexibleContent_TabbedSlider) {
	const [active, setActive] = useState(0);
	const interval = useRef(null);

	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.3,
	});

	useEffect(() => {
		if (inView) {
			interval.current = setInterval(() => {
				setActive((i) => (i + 1) % slides.length);
			}, 9000);
		}

		setActive(0);

		return () => {
			clearInterval(interval.current);
		};
	}, [interval, inView]);

	const overrideActive = (i) => {
		setActive(i);
		clearInterval(interval.current);

		interval.current = setInterval(() => {
			setActive((i) => (i + 1) % slides.length);
		}, 9000);
	};

	return (
		<Section {...section}>
			<div className="container" ref={ref}>
				{layout === "2" ? (
					<TabbedSliderTwo heading={heading} layout={layout} slides={slides} overrideActive={overrideActive} active={active} inView={inView} />
				) : (
					<TabbedSliderOne heading={heading} layout={layout} slides={slides} overrideActive={overrideActive} active={active} />
				)}
			</div>
		</Section>
	);
}

export const TabbedSliderOne = ({ heading, layout, slides, overrideActive, active }) => {
	return (
		<div className="max-w-[1195px]">
			<div className="mb-12 text-center md:mb-24">
				<Heading as="h2" size={HeadingSizeOption.h1} text={heading} />
			</div>
			<div className="flex flex-col-reverse items-center lg:flex-row">
				<div className="mt-12 w-full lg:mr-[70px] lg:mt-0 lg:w-[480px]">
					<Tabs layout={layout} slides={slides} active={active} overrideActive={overrideActive} />
				</div>
				<div className="w-full flex-1 md:w-1/2">
					<Images slides={slides} active={active} rounded />
				</div>
			</div>
		</div>
	);
};

export const TabbedSliderTwo = ({ heading, layout, slides, overrideActive, active, inView }) => {
	return (
		<div>
			{/* Heading */}
			<Border size={BorderSizeOption.Default}>
				<div className="max-w-[1057px]">
					<Heading as="h2" size={HeadingSizeOption.h2} text={heading} />
				</div>
			</Border>

			<div className="mt-[79px]">
				{/* Tabs */}
				<Tabs layout={layout} slides={slides} active={active} overrideActive={overrideActive} inView={inView} />

				<div className="mt-8 flex flex-wrap items-stretch overflow-hidden rounded-2xl">
					{/* Content */}
					<div className="rounded-t-[16px]x md:rounded-l-[16px]x w-full bg-purple-4 md:w-1/2">
						{slides?.map((slide, i) => (
							<motion.div
								key={`slide${i}`}
								className=""
								transition={{ duration: 0.5 }}
								initial={{ y: 0, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: 0, opacity: 0 }}
							>
								{i === active && (
									<div className="rounded-t-[15px] bg-purple-4 md:rounded-l-[15px] md:rounded-tr-none">
										<div className="px-6 py-12 lg:px-[70px] lg:py-[72px]">
											<Border size={BorderSizeOption.Default}>
												<Heading as="h4" size={HeadingSizeOption.h4} text={slides[active]?.heading} />
												<div className="mt-[11px] max-w-[407px]">
													<BodyCopy size={BodyCopySizeOption.Large} text={slides[active]?.content} />
												</div>
											</Border>
										</div>
									</div>
								)}
							</motion.div>
						))}
					</div>

					{/* Images */}
					<div className="w-full md:w-1/2">
						<Images slides={slides} active={active} fade />
					</div>
				</div>
			</div>
		</div>
	);
};

export const Tabs = ({ slides, active, overrideActive, layout, inView }) => {
	let bgColour = "bg-purple-5";
	let textColour = "";

	switch (slides[active].backgroundColour) {
		case "purple":
			bgColour = "!bg-purple-2";
			textColour = "text-green-1";
			break;
		case "green":
			bgColour = "!bg-green-2";
			textColour = "text-purple-2";

			break;
		case "orange":
			bgColour = "!bg-orange-2";
			break;
	}

	return (
		<>
			{layout === "2" ? (
				<ProgressTabs active={active} slides={slides} overrideActive={overrideActive} inView={inView} />
			) : (
				<>
					<ColourTabs active={active} slides={slides} overrideActive={overrideActive} bgColour={bgColour} inView={inView} />
				</>
			)}
		</>
	);
};

export const ProgressTabs = ({ slides, active, overrideActive, inView }) => {
	return (
		<div className="-ml-6 flex md:ml-[-56px]">
			{slides?.map((slide, i) => (
				<div key={`progTab${i}`} className={`w-1/4 pl-6 md:pl-[56px]`}>
					<ProgressTab onClick={() => overrideActive(i)} isActive={active === i} inView={inView} {...slide} />
				</div>
			))}
		</div>
	);
};

export const ProgressTab = ({ onClick, heading, isActive, background = "purple", inView, duration = 9 }) => {
	return (
		<div onClick={onClick} className={`flex h-full cursor-pointer flex-col justify-between transition-opacity duration-300 ease-in-out hover:opacity-50`}>
			<BodyCopy text={heading} size={BodyCopySizeOption.Default} />
			<div className={`relative mt-[17px] h-[2px] w-full ${background === "white" ? "bg-white bg-opacity-[0.45]" : "bg-purple-2 bg-opacity-[0.15]"} `}>
				<AnimatePresence>
					{inView && isActive && (
						<motion.div
							initial={{ width: "0%", opacity: 1 }}
							animate={{ width: "100%" }}
							// exit={{ opacity: 0 }}
							transition={{ duration: duration, ease: "linear" }}
							className="absolute inset-0 h-[2px] w-full bg-purple-2"
						/>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export const ColourTabs = ({ active, slides, overrideActive, bgColour }) => {
	return (
		<div className="colour-tabs space-y-[26px]">
			{slides?.map((slide, i) => (
				<BgSwitcher
					key={`slide${i}`}
					className={`rounded-r-[10px]`}
					bgColour={bgColour}
					isActive={active === i}
					onClick={() => overrideActive(i)}
					backgroundColour={slide?.backgroundColour}
				>
					<div className={`px-6 py-6 md:py-[30px] md:pb-7 md:pl-[32px] md:pr-[23px] ${active === i && "activeTab"} `}>
						<div className={`flex items-center space-x-[10px] ${active !== i && "eyecon"} `}>
							<Icon type={slide.iconIcon} />
							<ColourHeading isActive={active === i} backgroundColour={slide.backgroundColour} heading={slide?.heading} />
						</div>
						<div className={`mt-[13px] max-w-[415px] ${active !== i && "hidden md:block"}`}>
							<BodyCopy size={BodyCopySizeOption.Default} text={slide?.content} />{" "}
						</div>
					</div>
					{active === i && (
						<motion.div
							className={`progress-dark absolute bottom-0 left-0 bg-opacity-[0.16] ${active === i ? "w-2" : "w-0"} `}
							initial={{ height: "100%" }}
							animate={{ height: "1%" }}
							transition={{ duration: 5 }}
						></motion.div>
					)}

					<motion.div className={`absolute bottom-0 left-0 h-full bg-[#12026B] bg-opacity-[0.16] ${active === i ? "w-2" : "w-0"} `}></motion.div>
				</BgSwitcher>
			))}
		</div>
	);
};

export const ColourHeading = ({ isActive, backgroundColour, heading }) => {
	return (
		<div
			className={`tabHeading text-[18px] leading-[22px] transition-colors duration-300 ease-in-out ${
				!isActive && backgroundColour === "purple"
					? "text-purple-1"
					: !isActive && backgroundColour === "green"
					? "text-green-1"
					: !isActive && backgroundColour === "orange"
					? "text-orange-1"
					: ""
			} `}
		>
			{heading}
		</div>
	);
};

export const BgSwitcher = ({ className, backgroundColour, isActive, bgColour, onClick, children }) => {
	return (
		<div
			onClick={onClick}
			className={clsx(
				isActive && bgColour,
				backgroundColour === "purple" && "bg-purple-5",
				backgroundColour === "green" && "bg-green-6",
				backgroundColour === "orange" && "bg-orange-4",
				backgroundColour === "blue-3" && "bg-blue-3",
				className,
				"relative cursor-pointer transition-colors duration-300 ease-in-out"
			)}
		>
			{children}
		</div>
	);
};

export const Images = ({ slides, active, rounded, fade }) => {
	const [swiper, setSwiper] = useState(null);

	const slideTo = (index) => swiper?.slideTo(index);

	useLayoutEffect(() => {
		slideTo(active);
	}, [active]);

	return (
		<div className="h-full">
			<Swiper
				onSwiper={setSwiper}
				modules={[A11y, EffectFade]}
				className={`h-full w-full ${rounded && "overflow-hidden rounded-[10px]"}`}
				slidesPerView={1}
				draggable={false}
				effect={fade ? "fade" : "slide"}
				fadeEffect={{ crossFade: true }}
			>
				{slides.map((slide, i) => (
					<SwiperSlide key={`tabbedswiper${i}`} style={{ height: "100%" }}>
						<div className="h-full w-full">
							<Image image={slide.image} loading="eager" objectFit="cover" className="h-full w-full object-cover object-center" />
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};
