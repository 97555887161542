import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { Icon, IconType } from "~/components/elements/Icon";
import ExtraCurricularLogo from "~/assets/images/extra-curricular-logo.png";
import ExtraCurricularLogoDark from "~/assets/images/extra-curricular-logo-dark.png";
import { AnimatePresence, motion } from "framer-motion";
import FadeIn from "~/components/animations/FadeIn";
import useCategoryMenu from "~/hooks/useCategoryMenu";
import clsx from "clsx";

export default function ResourcesHeader({ setQuery, resourcesHeaderLight = false }) {
	if (typeof window === "undefined") return null;

	const [showSearch, setShowSearch] = useState(false);
	const [checkQuery, setCheckQuery] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		setQuery(checkQuery);
		setShowSearch(false);
		scrollToTop();
	};

	const [isScrolled, setIsScrolled] = useState(false);

	const handleNavigate = (e) => {
		navigate(e);
	};

	useEffect(() => {
		const handleScroll = () => {
			setIsScrolled(!isScrolled ? window.scrollY > 80 : window.scrollY > 81);
		};

		window.addEventListener("scroll", handleScroll, {
			passive: true,
		});

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const scrollToTop = () => {
		window && window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const { categories } = useCategoryMenu();

	return (
		<header
			className={`fixed left-0 right-0 top-0 z-30 transition-colors duration-300 ease-in-out ${
				resourcesHeaderLight ? "bg-purple-4 text-black" : "bg-dark-purple text-white"
			} `}
		>
			<div className={`flex flex-wrap border-b border-opacity-20 md:flex-nowrap ${resourcesHeaderLight ? "border-b-black" : "border-b-white"}`}>
				<div
					className={`z-30 flex w-1/2 items-center justify-center border-r border-opacity-20 md:w-[244px] ${
						resourcesHeaderLight ? "border-r-black" : "border-r-white"
					} `}
				>
					<Link
						to="/"
						className="flex w-full justify-center space-x-2 py-4 text-current transition-opacity duration-300 ease-in-out hover:opacity-50 md:py-0"
						title="Prevalent AI"
					>
						<Icon type={IconType.ArrowLeft} />
						<div className="w-24">
							<Icon type={IconType.Logo} />
						</div>
						<span className="screenreader-text">Prevalent AI</span>
					</Link>
				</div>
				<div
					className={`${
						isScrolled ? "py-3 md:py-6" : "py-4 md:py-10"
					} z-30 order-1 w-full border-t border-white border-opacity-20 transition-all duration-500 ease-in-out md:order-[0] md:w-auto md:flex-1 md:border-t-0`}
				>
					<Link to="/resources" className="block">
						<img src={resourcesHeaderLight ? ExtraCurricularLogoDark : ExtraCurricularLogo} alt="Extra Curricular" className="mx-auto w-[180px] md:w-[360px]" />
					</Link>
				</div>
				<div
					className={`relative z-30 flex w-1/2 items-center justify-center md:w-[244px] md:border-l ${
						resourcesHeaderLight ? "border-l-black" : "border-l-white"
					} border-opacity-20 text-[18px]`}
				>
					<AnimatePresence>
						{showSearch ? (
							<FadeIn>
								<div
									onClick={() => setShowSearch(false)}
									className="flex cursor-pointer items-center transition-opacity duration-300 ease-in-out hover:opacity-60"
								>
									<span className="mr-[7px]">Close</span>

									<Icon type={IconType.Close} />
								</div>
							</FadeIn>
						) : (
							<FadeIn>
								<div
									className="flex cursor-pointer items-center transition-opacity duration-300 ease-in-out hover:opacity-60"
									onClick={() => setShowSearch(true)}
								>
									<Icon type={IconType.Search} />
									<span className="ml-[7px] text-[16px]">Search Blog</span>
								</div>
							</FadeIn>
						)}
					</AnimatePresence>
				</div>
			</div>
			<AnimatePresence>
				{showSearch && (
					<motion.div
						className="fixed bottom-0 left-0 right-0 top-0 bg-dark-purple bg-opacity-[0.88]"
						style={{ willChange: "opacity" }}
						initial={{ opacity: 0, y: -"100%" }}
						animate={{ opacity: 1, y: "0%" }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
					></motion.div>
				)}
			</AnimatePresence>

			{showSearch ? (
				<div
					className={`relative z-30 flex w-full items-center justify-center border-b bg-dark-purple ${
						resourcesHeaderLight ? "border-b-black" : "border-b-white"
					} border-opacity-20`}
				>
					<div className="container z-30">
						<div className="mx-auto w-full max-w-[997px] py-16 md:py-[161px]">
							<div className="flex w-full items-center justify-between">
								<form className="flex w-full items-center justify-between" onSubmit={handleSubmit}>
									<input
										type="text"
										placeholder="Search blog"
										className="focus:outline-none max-w-[997px] appearance-none border-b-current bg-transparent text-[28px]"
										onChange={(e) => setCheckQuery(e.target.value)}
										autoFocus={showSearch}
									/>
									<button type="submit" className="search-icon-big">
										<Icon type={IconType.Search} />
									</button>
								</form>
							</div>

							<motion.div
								initial={{ width: "0%" }}
								animate={{ width: "100%" }}
								transition={{ duration: 0.3 }}
								className={`w-full ${resourcesHeaderLight ? "bg-black" : "bg-white"} mt-[31px] h-[1px]`}
							></motion.div>
						</div>
					</div>
				</div>
			) : (
				<div className={`border-b ${resourcesHeaderLight ? "border-b-black" : "border-b-white"} border-opacity-20`}>
					<nav className={`hidden justify-center md:flex`}>
						<ul className="flex space-x-10">
							<li>
								<Link
									to="/resources"
									className={`${isScrolled ? "py-3 md:py-6" : "py-4 md:py-10"} hover:transition-colors hover:duration-300 hover:ease-in-out ${
										resourcesHeaderLight ? "hover:text-purple" : "hover:text-green-2"
									} flex items-center`}
									activeClassName={` border-b ${resourcesHeaderLight ? "text-purple border-b-purple" : "text-green-2 border-b-green-2"} `}
								>
									<div className={`fw-icon w-3`}>
										<Icon type={IconType.All} />
									</div>
									<span className="ml-[10px]">All</span>
								</Link>
							</li>

							{categories?.map((category, i) => (
								<li key={`catNav${i}`}>
									<Link
										to={category?.category.uri}
										className={`${isScrolled ? "py-3 md:py-6" : "py-4 md:py-10"} hover:transition-colors hover:duration-300 hover:ease-in-out ${
											resourcesHeaderLight ? "hover:text-purple" : "hover:text-green-2"
										} z-[150] flex items-center`}
										activeClassName="text-green-2 border-b border-b-green-2"
									>
										<div
											className={clsx(
												"fw-icon w-3",
												["video"]?.includes(category?.category?.category?.icon) && "!w-4",
												["caseStudy"]?.includes(category?.category?.category?.icon) && "!w-5"
											)}
										>
											<Icon type={category?.category?.category?.icon} />
										</div>
										<span className="ml-[10px]">{category?.category.name}</span>
									</Link>
								</li>
							))}
						</ul>
					</nav>
					<div className="flex justify-center p-2 md:hidden">
						<form
							className="relative flex justify-center bg-transparent"
							onSubmit={(event) => {
								event.preventDefault();
							}}
						>
							<select
								onChange={(e) => handleNavigate(e.target.value)}
								className="outline-none relative appearance-none border-b bg-transparent py-1 pr-4 text-current"
							>
								{categories?.map((category, i) => (
									<option key={`categoryNavItem${i}`} value={category?.category?.uri} selected={category?.category?.uri === location.pathname}>
										{category?.category?.name}
									</option>
								))}
							</select>
							<span className={`custom-arrow absolute right-0 top-0 flex h-full items-center text-current`}>
								<Icon type={IconType.DropDown} />
							</span>
						</form>
					</div>
				</div>
			)}
		</header>
	);
}
