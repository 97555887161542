import { Link } from "gatsby";
import React from "react";
import { Icon, IconType } from "~/components/elements/Icon";

const HeaderBanner = (props) => {
	const { allWpPost } = props;

	return (
		<div className="mx-auto max-w-[1350px] bg-purple-2">
			<Link
				to={allWpPost.nodes[0].uri}
				className="flex h-full w-full items-center justify-center py-2.5 text-[14px] tracking-[-0.02em] transition-opacity duration-300 ease-in-out hover:opacity-50"
			>
				<span className="mr-1 font-medium">Latest News</span> - {allWpPost.nodes[0].title}
				<div className="fw-icon mb-[2px] ml-1.5 w-2">
					<Icon type={IconType.ArrowRightDown} />
				</div>
			</Link>
		</div>
	);
};

export default HeaderBanner;
