import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";
import React, { useState } from "react";
import { Icon } from "~/components/elements/Icon";
import MenuLinkItem from "./MenuLinkItem";

const HeaderNav = ({ link, subnav, i, enterHandler, exitHandler, simple, setActiveMenu, activeMenu }) => {
	return (
		<div className="relative">
			<Link onMouseEnter={() => enterHandler(i)} className="flex items-center transition-colors duration-200 hover:text-purple-2" to={link?.url}>
				<span>{link?.title}</span>
				{subnav !== "none" && (
					<div className="ml-[4px]">
						<Icon type={"caret"} />
					</div>
				)}
			</Link>

			<AnimatePresence>{subnav === "simple" && activeMenu === i && <SimpleMenu setActiveMenu={setActiveMenu} items={simple?.items} />}</AnimatePresence>
		</div>
	);
};

const SimpleMenu = ({ setActiveMenu, items }) => (
	<motion.div
		initial={{ opacity: 0 }}
		animate={{ opacity: 1 }}
		exit={{ opacity: 0 }}
		transition={{ duration: 0.3 }}
		// onMouseLeave={() => setActiveMenu(null)}
		className="header-nav-simple-menu absolute left-[-144px] top-[45px] w-[438px] rounded-b-lg bg-white"
	>
		<div className="space-y-[25px] px-[33px] pb-[30px] pt-[30px]">
			{items?.map((item, i) => (
				<MenuLinkItem key={`simple-item-${i}`} {...item} showBottomBorder={i !== items?.length - 1} />
			))}
		</div>
	</motion.div>
);

export default HeaderNav;
