import { Link } from "gatsby";
import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { BodyCopy, BodyCopySizeOption } from "~/components/elements/typography/BodyCopy";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { IconType } from "~/components/elements/Icon";
import Image from "~/components/elements/Image";
import MenuLinkItem from "./MenuLinkItem";
import clsx from "clsx";

const MobileDropDownItem = (props) => {
	const { link, label, childItems, i, onDudClick, megaMenuCTALinks, megaMenuHeadingLink, altLayout } = props;
	const [openMenu, setOpenMenu] = useState(false);

	// return null;

	return (
		<div className="w-full">
			<div className="flex cursor-pointer items-center px-6" onClick={childItems?.items?.length > 1 ? () => setOpenMenu(!openMenu) : undefined}>
				{/* Heading */}
				<div className="pb-[50px]x w-full font-heading text-[26px] leading-[25px] text-white">
					<Link onClick={childItems?.items?.length > 1 ? null : onDudClick} to={link || "#"}>
						{label}
					</Link>
				</div>

				{/* Arrow */}
				{childItems?.items?.length > 1 && (
					<svg
						className={clsx("rotate-180 transform transition-transform duration-200 ease-in-out", openMenu && "rotate-0")}
						width="21"
						height="21"
						viewBox="0 0 21 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="10.7587" cy="10.2588" r="9.7412" transform="rotate(-90 10.7587 10.2588)" stroke="white" />
						<path
							d="M7.40676 9.80688L7.4001 9.80023C7.22426 9.60827 7.23337 9.31122 7.42007 9.13012L10.4252 6.12494C10.6088 5.94209 10.9055 5.94174 11.0894 6.12424L14.0963 9.13112C14.283 9.31222 14.2921 9.60926 14.1162 9.80123C14.0287 9.89231 13.9085 9.9438 13.7824 9.9452C13.6563 9.9466 13.5351 9.89687 13.4461 9.80789L11.6736 8.03539C11.5987 7.96042 11.4855 7.93765 11.3874 7.97828C11.2894 8.01892 11.2256 8.1149 11.226 8.22103L11.2252 14.0646C11.2256 14.1903 11.1748 14.3112 11.0848 14.3991C10.9951 14.4874 10.8735 14.5361 10.7478 14.5336C10.4872 14.5224 10.2833 14.3059 10.2872 14.0457L10.2854 8.21924C10.2854 8.11275 10.2217 8.01677 10.1233 7.97649C10.0252 7.93585 9.91205 7.95862 9.83743 8.03394L8.06564 9.80573C7.88314 9.98613 7.58959 9.98684 7.40674 9.80678L7.40676 9.80688Z"
							fill="white"
						/>
					</svg>
				)}
			</div>

			{childItems?.cta && openMenu && (
				<motion.div
					initial="collapsed"
					animate="open"
					exit="collapsed"
					variants={{
						open: { opacity: 1, height: "auto" },
						collapsed: { opacity: 0, height: 0 },
					}}
					transition={{ duration: 0.3 }}
					className="mt-6 px-6"
				>
					<div
						// onClick={onDudClick}
						// to={childItems?.cta?.link.url}
						className="block rounded-[5px] bg-green-4 text-black transition-colors duration-300 ease-in-out hover:bg-green-7"
					>
						<div className="pb-[26px] pl-[19px] pr-[14px] pt-[17px]">
							<Link to={megaMenuHeadingLink?.url}>
								{/* Heading */}
								<div className="font-heading text-[19px] leading-[24px]">{childItems?.cta?.heading}</div>

								{/* Text */}
								<div className="mt-[15px]">
									<BodyCopy size={BodyCopySizeOption.Default} text={childItems?.cta?.content} />
								</div>
							</Link>

							<div className="mt-5 flex items-end justify-between">
								<div className="flex flex-shrink-0 flex-col gap-2">
									{megaMenuCTALinks.length > 0 &&
										megaMenuCTALinks.map((link, i) => (
											<Button
												preLinkText={i + 1}
												key={`mega-menu-cta-link-${i}`}
												hideIcon
												type={ButtonType.Text}
												link={link?.link}
												icon={IconType.ArrowRightDown}
											/>
										))}
								</div>

								<Image image={childItems?.cta?.image} className="pointer-events-none h-full w-full max-w-[95px] sm:max-w-[200px]" />
							</div>
						</div>
					</div>
				</motion.div>
			)}

			<AnimatePresence exitBeforeEnter>
				{openMenu && (
					<div className="space-y-[24px] px-6 pt-6">
						{childItems?.items?.map((childItem, i) => (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{
									duration: 0.4,
									delay: (i + 1) / 16,
									ease: "easeIn",
								}}
								key={`mobileChildItem${i}`}
							>
								<MenuLinkItem
									linkClasses={clsx(
										i === 0 && altLayout && "rounded-lg !text-black bg-purple-4 transition-colors duration-300 ease-in-out hover:bg-purple-3 p-5"
									)}
									contentClass={clsx("!opacity-60", i === 0 && altLayout ? "text-black" : "text-white")}
									showBottomBorder={i !== 0 && !altLayout}
									{...childItem}
									onClick={onDudClick}
									borderColour="#FFFFFF"
								/>
							</motion.div>
						))}
					</div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default MobileDropDownItem;
