import { graphql, useStaticQuery } from "gatsby";

const useCategoryMenu = () => {
	const data = useStaticQuery(graphql`
		query {
			wp {
				header {
					header {
						resourcesHeader {
							categories {
								category {
									uri
									name
									category {
										icon
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	return data?.wp?.header?.header?.resourcesHeader;
};

export default useCategoryMenu;
