import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";
import { NavToggle } from "~/components/elements/buttons/NavToggle";
import Image from "~/components/elements/Image";
import MobileDropDownItem from "./MobileDropDown";
import PrevIcon from "~/assets/images/prev-icon.png";

const MobileNav = ({ headerMenu, headerLink, headerLogo }) => {
	const [showMenu, setSetShowMenu] = useState(false);

	return (
		<header className="">
			<div className="fixed top-0 z-[100] flex h-full max-h-[58px] w-full items-center justify-between border-b-[0.75px] border-white border-opacity-25 bg-dark-purple px-[15px] py-[13px] lg:hidden">
				<div className="flex h-full items-center">
					<div onClick={() => setSetShowMenu(!showMenu)} className="w-[23px]">
						<NavToggle dark={true} isOpen={showMenu} />
					</div>

					<div className="mx-[10px] ml-[8px] h-full max-h-[34px] w-[0.5px] bg-white bg-opacity-[0.35]"></div>
					<Link to="/">
						<Image image={headerLogo} className="max-w-[83px] invert" />
					</Link>
				</div>

				<Link
					to={headerLink?.[0]?.link?.url}
					className="rounded-[3px] bg-purple-2 px-[40px] py-[10px] text-[11.8px] font-normal leading-[0.943] tracking-[-0.02em] text-white transition-colors duration-200 ease-in-out hover:bg-dark-purple"
				>
					{headerLink?.[0]?.link?.title}
				</Link>
			</div>

			<AnimatePresence>
				{showMenu && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2, ease: "easeInOut" }}
						className="fixed bottom-0 left-0 right-0 top-0 z-[50] flex h-screen w-screen flex-col justify-between overflow-y-auto bg-dark-purple pb-12 pt-[58px] lg:hidden"
					>
						<div className="space-y-8 pt-10">
							{headerMenu?.map((menuItem, i) => {
								return (
									<MobileDropDownItem
										i={i}
										key={`menuItem${i}`}
										link={menuItem?.link?.url}
										label={menuItem?.link?.title}
										onDudClick={() => {
											setSetShowMenu(false);
										}}
										childItems={(menuItem?.simple?.items?.length > 0 && menuItem?.simple) || (menuItem?.megaMenu?.items?.length > 0 && menuItem?.megaMenu)}
										megaMenuHeadingLink={menuItem?.megaMenu?.cta?.headingLink}
										megaMenuCTALinks={menuItem?.megaMenu?.cta?.links}
										altLayout={menuItem?.megaMenu?.items?.length > 0}
									/>
								);
							})}
						</div>

						<div className="mt-[54px] flex w-full flex-col items-center justify-center space-y-[11px] lg:mt-0">
							<span className="opacity-50">© Prevalent AI 2021</span>
							<div className="h-full w-full max-w-[30px]">
								<img src={PrevIcon} alt="" />
							</div>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</header>
	);
};

export default MobileNav;
