import React, { useState } from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { Icon } from "~/components/elements/Icon";
import clsx from "clsx";

const MenuLinkItem = ({
	link,
	simpleLink,
	summary,
	contentClass = "text-black",
	// borderColour = "#000000",
	largeTitle = false,
	onClick = null,
	showBottomBorder = true,
	isLarge = false,
	linkClasses = "",
	classes = "",
}) => {
	const [onHover, setOnHover] = useState(false);

	return (
		<div className={clsx("group cursor-pointer", classes)} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
			<Link className={clsx(linkClasses, "block")} onClick={onClick} to={link?.url || simpleLink?.url}>
				<div className="flex items-center justify-between">
					<span className={clsx(largeTitle ? "text-24" : "text-[21px]", "font-heading leading-[27px] tracking-[-0.01em]")}>
						{link?.title || simpleLink?.title}
					</span>
					<div className="-translate-x-2 -translate-y-1 opacity-0 transition-[transform,opacity] duration-300 ease-in-out group-hover:translate-x-0 group-hover:translate-y-0 group-hover:opacity-100">
						<Icon type={"arrowRightDown"} />
					</div>
				</div>
				<div className={`mt-3 font-body text-[16px] leading-[1.1] tracking-[-0.02em] opacity-60 lg:leading-[1.475] ${contentClass}`}>{summary}</div>
			</Link>

			{showBottomBorder && (
				<div className={clsx("relative h-[1px] w-full", isLarge ? "mt-6 xl:mt-24" : "mt-[22px]")}>
					<div className="absolute inset-0 bg-black bg-opacity-[.14]" />

					<motion.div
						animate={{
							width: onHover ? "100%" : 0,
						}}
						transition={{ duration: 0.3 }}
						className="absolute inset-0 bg-purple-2"
					/>
				</div>
			)}
		</div>
	);
};

export default MenuLinkItem;
