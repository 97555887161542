import * as React from "react";
import { motion } from "framer-motion";

const Path = (props) => <motion.path fill="transparent" strokeWidth="1.5" strokeLinecap="" {...props} />;

export const NavToggle = ({ isOpen, dark }) => (
	<svg className="z-50 mt-1 h-full w-full" width="23" height="23" viewBox="0 0 23 23">
		<Path
			stroke={isOpen ? "white" : dark ? "white" : "black"}
			initial="closed"
			animate={isOpen ? "open" : "closed"}
			variants={{
				closed: { d: "M 2 2.5 L 20 2.5" },
				open: { d: "M 3 16.5 L 17 2.5" },
			}}
			transition={{ duration: 0.4 }}
		/>
		<Path
			stroke={isOpen ? "white" : dark ? "white" : "black"}
			initial="closed"
			animate={isOpen ? "open" : "closed"}
			d="M 2 9.423 L 20 9.423"
			variants={{
				closed: { opacity: 1 },
				open: { opacity: 0 },
			}}
			transition={{ duration: 0.4 }}
		/>
		<Path
			stroke={isOpen ? "white" : dark ? "white" : "black"}
			initial="closed"
			animate={isOpen ? "open" : "closed"}
			variants={{
				closed: { d: "M 2 16.346 L 20 16.346" },
				open: { d: "M 3 2.5 L 17 16.346" },
			}}
			transition={{ duration: 0.4 }}
		/>
	</svg>
);
